<template>
    <div>
        <el-form label-width="100px">
            <el-form-item label="卡牌图片">
                <AvatarUpload @uploadSuccess="handleImgUpload" :outImgUrl="form.img"></AvatarUpload>
            </el-form-item>
            <el-form-item label="介绍">
                <el-input type="textarea" rows="5" v-model="form.text"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="success" @click="submitParentForm">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import AvatarUpload from "@/components/admin/avatar-upload"
    export default {
        name: "card-deck",
        components:{AvatarUpload},
        data() {
            return {
                form: {
                    img:"",
                    text:"",
                    isEdit:false,
                    index:0,
                }
            }
        },
        props: {
            cardEditVal: {
                type: Object,
                default: function () {
                    return {};
                }
            },
        },
        methods: {
            handleImgUpload(obj){
                this.form.img = obj.imgUrl;
            },
            submitParentForm(){
                this.$emit("passSubmitCard",this.form)
            }
        },
        created() {
            this.form.img = this.cardEditVal.img;
            this.form.text = this.cardEditVal.text;
            this.form.isEdit = this.cardEditVal.isEdit;
            this.form.index = this.cardEditVal.index;
        }
    }
</script>

<style scoped>

</style>
